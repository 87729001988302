<template>
    <div>
        <v-timeline>
            <v-timeline-item v-for="(item,index) of records" small :key="item.id" :color="`${colors[index%colors.length]} lighten-2`">
                <template v-slot:opposite>
                    <span :class="`headline font-weight-light ${colors[index%colors.length]}--text`">{{item.version}}</span>
                </template>
                <v-card class="elevation-2">
                    <v-card-title>
                        <span :class="`font-weight-light mb-4 ${colors[index%colors.length]}--text`">
                            {{item.create_time}}
                        </span>      
                    </v-card-title>
                    <v-card-text>
                        <p class="tag" v-html="item.detail"></p>
                    </v-card-text>
                </v-card>
            </v-timeline-item>
        </v-timeline>
    </div>
</template>

<script>
    import moment from 'moment';
    import showdown from "showdown";
    export default {
        name: 'timeline',
        data: function () {
            return {
                records:[],
                colors:["cyan","green","pink",'amber','orange']
            }
        },
        methods: {
            getData() {
                let that = this
                var converter = new showdown.Converter(); 
                converter.setFlavor('github');
                this.axios.get(this.$store.state.base_url + "/version/front/list", {
                    params: that.q
                }).then(function (response) {
                    if (response.data.code == 200) {
                        let records = response.data.data
                        records.forEach(element => {
                            element.create_time = moment(element.create_time).format('YYYY-MM-DD');
                            element.detail = converter.makeHtml(element.detail)
                        });
                        that.records = records
                    } else {
                        that.alert.msg = "获取数据失败"
                        that.alert.visiable = true
                    }

                }).catch(function (error) {
                    that.alert.msg = error
                    that.alert.visiable = true
                })
            }
        },
        created() {
            this.getData()
        }
    }
</script>

<style>
.tag p{
    margin-bottom: 0;
}
</style>